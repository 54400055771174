import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { i18n } from '@/i18n';
import VueI18n from 'vue-i18n';
import LocaleMessage = VueI18n.LocaleMessage;

@Module({ namespaced: true })
class Header extends VuexModule {
  public title = i18n.t('app.title-main');

  @Mutation
  public setTitle(title: string): void {
    this.title = title;
  }

  @Action
  public updateTitle(title: string | LocaleMessage) {
    this.context.commit('setTitle', title);
  }

  get getTitle() {
    return this.title;
  }
}

export default Header;
