import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StatusEnum } from '@/domain/interface/activiteit/StatusEnum';

@Module({ namespaced: true })
class Activiteit extends VuexModule {
  public readOnly = false;
  public status = StatusEnum.NULL;
  public beheerschermRedirect = false;

  @Mutation
  public setReadOnly(readOnly: boolean): void {
    this.readOnly = readOnly;
  }

  @Action
  public updateReadOnly(readOnly: boolean): void {
    this.context.commit('setReadOnly', readOnly);
  }

  @Mutation
  public setStatus(status: StatusEnum): void {
    this.status = status;
  }

  @Mutation
  public setBeheerschermRedirect(beheerschermRedirect: boolean): void {
    this.beheerschermRedirect = beheerschermRedirect;
  }

  @Action
  public setRedirect(beheerschermRedirect: boolean): void {
    this.context.commit('setBeheerschermRedirect', beheerschermRedirect);
  }

  @Action
  public setStatusIngediend(): void {
    this.context.commit('setStatus', StatusEnum.INGEDIEND);
  }

  @Action
  public setStatusGevalideerdEnIngediend(): void {
    this.context.commit('setStatus', StatusEnum.GEVALIDEERD_EN_INGEDIEND);
  }

  @Action
  public setStatusTeValideren(): void {
    this.context.commit('setStatus', StatusEnum.TE_VALIDEREN);
  }

  @Action
  public setStatusInBewerking(): void {
    this.context.commit('setStatus', StatusEnum.IN_BEWERKING);
  }

  @Action
  public resetStatus(): void {
    this.context.commit('setStatus', StatusEnum.NULL);
  }

  @Action
  public setStatusGeweigerd(): void {
    this.context.commit('setStatus', StatusEnum.GEWEIGERD);
  }

  get isReadOnly() {
    return this.readOnly;
  }

  get overzichtRedirect() {
    if (this.beheerschermRedirect) {
      return 'beheerscherm';
    }
    return 'activiteit-overzicht';
  }

  get isIngediendStatus() {
    return StatusEnum.INGEDIEND === this.status;
  }

  get isTeValiderenStatus() {
    return StatusEnum.TE_VALIDEREN === this.status;
  }

  get isGevalideerdEnIngediendStatus() {
    return StatusEnum.GEVALIDEERD_EN_INGEDIEND === this.status;
  }

  get isInBewerkingStatus() {
    return StatusEnum.IN_BEWERKING === this.status;
  }

  get isEmptyStatus() {
    return StatusEnum.NULL === this.status;
  }

  get isInAanpasbareStatus() {
    return (
      this.isEmptyStatus || this.isInBewerkingStatus || this.isTeValiderenStatus
    );
  }
}

export default Activiteit;
