import { UserManager, User } from 'oidc-client';

export default class AuthorizationService {
  private userManager: UserManager;

  constructor() {
    this.userManager = new UserManager(
      JSON.parse(process.env.VUE_APP_ACM_CONFIG)
    );
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public logout(): Promise<void> {
    this.userManager
      .removeUser()
      .then(() => console.log('User removed from all storages.'));
    return this.userManager.signoutRedirect();
  }

  public isLoggedIn(): Promise<boolean> {
    return this.userManager.getUser().then((user) => {
      return user != null && !user.expired;
    });
  }

  public getIdToken(): Promise<string | undefined> {
    return this.userManager.getUser().then((user) => {
      return user?.id_token;
    });
  }

  public isInRolControleur(): Promise<boolean> {
    return this.userManager.getUser().then((user) => {
      return !!user?.profile['veka_stook_rol_3d']?.find(
        (rol: string) =>
          rol === 'VEKAStooktoestellenGebruiker-controleur:OVO000090'
      );
    });
  }
}
