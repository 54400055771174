import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=2ac258a9"
import script from "./Header.vue?vue&type=script&lang=ts"
export * from "./Header.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VSkeletonLoader,VSpacer})
