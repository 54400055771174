import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DefaultValuesMixin extends Vue {
  get $defaultFilterColWidth() {
    return {
      cols: '12',
      md: '6',
      lg: '4',
      xl: '3',
    };
  }

  get $defaultColWidth() {
    return {
      cols: '12',
      sm: '12',
      md: '4',
      lg: '6',
      xl: '6',
    };
  }

  get $footerImageColWidth() {
    return {
      cols: '12',
      xs: '12',
      sm: '12',
      md: '3',
      lg: '2',
      xl: '2',
    };
  }

  get $footerTextColWidth() {
    return {
      cols: '12',
      xs: '12',
      sm: '12',
      md: '8',
      lg: '8',
      xl: '8',
    };
  }
}
