import Vue from 'vue';
import Vuex from 'vuex';
import Errors from '@/store/modules/errors';
import Loader from '@/store/modules/loader';
import Gebruiker from '@/store/modules/gebruiker';
import Header from '@/store/modules/header';
import Activiteit from '@/store/modules/activiteit';
import SuccessAlertMessages from '@/store/modules/successAlertMessages';
import WarningAlertMessages from '@/store/modules/warningAlertMessages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
  },
  getters: {
    appVersion: (state) => state.packageVersion,
  },
  mutations: {},
  actions: {},
  modules: {
    Loader,
    Errors,
    SuccessAlertMessages,
    WarningAlertMessages,
    Gebruiker,
    Header,
    Activiteit,
  },
});
