import Vue from 'vue';
import App from './App.vue';
import '@/registerServiceWorker';
import '@/assets/scss/veka-theme.scss';
import '@/components/filters/globalFilters';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import router from '@/router';
import { i18n } from '@/i18n';
import axios from 'axios';
import AuthorizationService from '@/services/AuthorizationService';
import DefaultValuesMixin from '@/mixins/DefaultValuesMixin';
import ConfigHelper from '@/util/ConfigHelper';

const auth = new AuthorizationService();
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.request.use(
  async function (config) {
    /**
     * If's order is important! Do not move to specifiek interceptors, unless you know what you are doing!
     */

    /*
     * Request to basisregister should go through proxy (defined in config).
     * Responses still have the original url and should be replaced with the proxy url before sending the request.
     */
    if (config.url?.startsWith('https://api.basisregisters.vlaanderen.be/v2')) {
      config.url = config.url?.replace(
        'https://api.basisregisters.vlaanderen.be/v2',
        ConfigHelper.getBasisRegistersUrl()
      );
    }

    // add Authorization header only for energie api calls.
    // Energie api calls start with the same value as baseURL or do not start with https:// (breadcrumbs)
    if (
      !config.url?.startsWith('https://') ||
      config.url?.startsWith(axios.defaults.baseURL as string) ||
      config.url?.startsWith(ConfigHelper.getBasisRegistersUrl())
    ) {
      await auth.getUser().then((user) => {
        if (user?.expired) {
          auth.login();
        }
      });
      await auth.getIdToken().then((idToken) => {
        config.headers.authorization = idToken ? `Bearer ${idToken}` : '';
      });
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;

Vue.mixin(DefaultValuesMixin);

const envIsLeeromgeving = process.env.VUE_APP_LEEROMGEVING === 'true';

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// needed to use the translation functions in TS files.
export { i18n, envIsLeeromgeving };
